import React, { useEffect, useState } from 'react';
import AppLayout from '@cloudscape-design/components/app-layout';
import Container from '@cloudscape-design/components/container';
import Header from '@cloudscape-design/components/header';
import Spinner from '@cloudscape-design/components/spinner';
import TopNavigation, { TopNavigationProps } from '@cloudscape-design/components/top-navigation';
import { FlashbarProps } from "@cloudscape-design/components/flashbar";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { TelegramUser } from 'telegram-login-button';
import { useLastFM } from 'use-last-fm';
import moment from 'moment';

import * as API from './api';
import SigninModal from './signin-modal';
import LocationFlash from './location-flash';
import LocationForm from './location-form';

import nowPlaying from './now_playing.gif';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [status, setStatus] = useState('In your heart');
  const [emote, setEmote] = useState('🥰');
  const [modalVisible, setModalVisible] = useState(false);
  const [nav, setNav] = useState<TopNavigationProps.Utility[]>([]);
  const [username, setUsername] = useState('');

  const lastFM = useLastFM('SparkyFen', process.env.REACT_APP_LAST_FM_API_TOKEN || '');

  useEffect(() => {
    API.getLocation().then((result) => {
      setLoading(false);
      setStatus(result.location.status);
      setEmote(result.location.emote);
    }).catch(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const tokenItem = localStorage.getItem('user-token');
    if (tokenItem) {
      const token = JSON.parse(tokenItem);
      if (moment.utc(token.expires).isBefore(moment.utc())) {
        // Expired
        setUsername('');
      } else {
        setUsername(token.username);
      }
    }
  }, []);

  useEffect(() => {
    if (username !== '') {
      setNav(current => [
        {
          type: "menu-dropdown",
          text: username,
          iconName: "user-profile",
          items: [
            { id: "signout", text: "Sign out" }
          ],
          onItemClick: handleItemClick
        }
      ]);
    } else {
      setNav(current => [
        {
          type: "button",
          text: "Sign in",
          iconName: "user-profile",
          onClick: handleSignin
        }
      ]);
    }
  }, [username]);

  const handleItemClick = (event: any) => {
    switch(event.detail.id) {
      case 'signout':
        handleSignOut();
      break;
      default:
        console.warn('Unknown item click.');
      break;
    }
  };

  const handleSignOut = () => {
    localStorage.removeItem('user-token');
    setUsername('');
  };

  const handleLogin = async (user: TelegramUser) => {
    const data = await API.login(user);
    localStorage.setItem('user-token', JSON.stringify({username: user.username, token: data.token, expires: data.expires_at}));
    setModalVisible(false);
    setUsername(user.username);
  };

  const handleSignin = () => {
    setModalVisible(true);
  };

  const handleLocationChange = async (event: any) => {
    const location: API.Location = {
      status,
      emote,
    };
    try {
      const result : API.SetLoctionResult = await API.setLocation(location);
      if(result.location) {
        setStatus(result.location.location.status);
        setEmote(result.location.location.emote);
      }
      setItems([{
        type: result.location ? "success" : "error",
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => setItems([]),
        content: (
          <>
            {result.message}
          </>
        ),
        id: "message_1"
      }]);
    } catch(error) {
      console.error(error);
    }
  };

  return (
    <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
      <TopNavigation
        identity={{
          href: "#",
          title: "Where is da flompy?",
        }}
        utilities={nav}
        i18nStrings={{
          searchDismissIconAriaLabel: "Close search",
          overflowMenuTriggerText: "More",
          overflowMenuTitleText: "All",
          overflowMenuBackIconAriaLabel: "Back",
          overflowMenuDismissIconAriaLabel: "Close menu"
        }}
      />
      <SigninModal visible={modalVisible} setVisible={setModalVisible} onSignin={handleLogin} />
      <AppLayout
        headerSelector="#h"
        contentHeader={<Header variant="h1">Where is Sparky?</Header>}
        notifications={<LocationFlash items={items} />}
        content={
          <>
            <SpaceBetween direction="vertical" size="m">
              <Container>
                {loading ? <Spinner size='large'/> :
                  <div>
                    <h1>{status} {emote}</h1>
                    {
                      lastFM.status !== 'playing' ? null
                      : <p>
                          <img src={nowPlaying} alt="Now playing" />
                          {` ${lastFM.song.name} by ${lastFM.song.artist}`}
                        </p>
                    }
                  </div>
                }
              </Container>
              {username === 'SparkyFen' ?
                <Container>
                 <LocationForm
                  loading={loading}
                  handleOnSubmit={handleLocationChange}
                  status={status}
                  emote={emote}
                  setStatus={setStatus}
                  setEmote={setEmote}
                />
                </Container>
              : null}
            </SpaceBetween>
          </>
        }
        navigationHide={true}
        toolsHide={true}
      />
    </div>
  );
}

export default App;
