import moment from 'moment';
import { TelegramUser } from 'telegram-login-button';

const validateToken = () => {
  const tokenItem = localStorage.getItem('user-token');
  if (tokenItem) {
    const token = JSON.parse(tokenItem);
    if (moment.utc(token.expires).isBefore(moment.utc())) {
      localStorage.removeItem('user-token');
      window.location.reload();
    } else {
      return token;
    }
  }
}

export async function getLocation() {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/location`);
    const result = await resp.json();
    if (!resp.ok) {
      throw new Error(result.message);
    }
    return result;
  } catch(error) {
    console.error(error);
    throw error;
  }
};

export async function login(user: TelegramUser) {
  const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/user`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });
  return await response.json();
};

export type Location = {
  status: string;
  emote: string;
}
export type SetLoctionResult = {
  message: string;
  location: {
    location: Location;
    id: string;
    user_id: string;
    created_at: string;
    ttl: number;
  }
};
export async function setLocation(location: Location) {
  const token = validateToken();
  const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/location`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.token}`,
    },
    body: JSON.stringify(location),
  });
  return await response.json();
};